exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-to-find-js": () => import("./../../../src/pages/map-to-find.js" /* webpackChunkName: "component---src-pages-map-to-find-js" */),
  "component---src-pages-services-corporate-js": () => import("./../../../src/pages/services/corporate.js" /* webpackChunkName: "component---src-pages-services-corporate-js" */),
  "component---src-pages-services-crm-js": () => import("./../../../src/pages/services/crm.js" /* webpackChunkName: "component---src-pages-services-crm-js" */),
  "component---src-pages-services-delivery-js": () => import("./../../../src/pages/services/delivery.js" /* webpackChunkName: "component---src-pages-services-delivery-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-outsource-js": () => import("./../../../src/pages/services/outsource.js" /* webpackChunkName: "component---src-pages-services-outsource-js" */),
  "component---src-pages-services-telephone-exchange-js": () => import("./../../../src/pages/services/telephone-exchange.js" /* webpackChunkName: "component---src-pages-services-telephone-exchange-js" */),
  "component---src-pages-services-video-js": () => import("./../../../src/pages/services/video.js" /* webpackChunkName: "component---src-pages-services-video-js" */)
}

